<template>
  <div
    class="grid xs:grid-flow-col gap-4 fixed bottom-0 w-100 bg-black-900"
    :class="show || minimizedDisplay.length > 0 || minimizedWidgets.length > 0 ? 'minimizer_body' : 'w-0'"
    v-if="minimizedDisplay.length > 0 || minimizedWidgets.length > 0"
  >
    <div class="grid xs:grid-flow-col gap-3" v-if="show">
      <div
        class="hoverColor"
        :key="index"
        v-for="(disp, index) in minimizedDisplay"
      >
        <AdminSubNavbar
          :bottomMinimizer="true"
          :isDisplay="true"
          :displayDetails="disp"
          :minimizedIndex="index"
          :onMinMaxBck="onMinMax"
          :displayId="this.displayId"
          :isMinimize="isMinimize"
        />
      </div>
      <div
        class=""
        :key="index"
        v-for="(widget, index) in minimizedWidgets"
      >
        <AdminSubNavbar
          :bottomMinimizer="true"
          :isDisplay="false"
          :widgetDetails="widget"
          :minimizedIndex="index"
        />
      </div>
    </div>
    <a class="icon_minimizer" href="#" @click="show = !show">
      <p v-if="!show">
        <!-- <i class="fa fa-eye" aria-hidden="true"></i> -->
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M0 4H4V0H0V4ZM6 16H10V12H6V16ZM0 16H4V12H0V16ZM0 10H4V6H0V10ZM6 10H10V6H6V10ZM12 0V4H16V0H12ZM6 4H10V0H6V4ZM12 10H16V6H12V10ZM12 16H16V12H12V16Z" fill="white"/>
</svg>
      </p>
      <p v-else>X</p>
    </a>
  </div>
</template>

<script>
import AdminSubNavbar from "./AdminSubNavbar.vue";
import HideShow from "./HideShow.vue";
export default {
  name: "BottomMinimizer",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    minimizedDisplay() {
      let minimizedDisplay = this.$store.state.disp.minimizedDisplays;
      return minimizedDisplay;
    },
    minimizedWidgets() {
      let minimizedWidgets = this.$store.state.rect.minimizedWidgets;
      return minimizedWidgets;
    },
  },
  components: {
    AdminSubNavbar,
    HideShow,
  },
  props: {
    index: Number,
    displayId: String,

    onMinMax: Function,
    isMinimize: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.icon_minimizer {
  /* padding: 13px 0px 0 10px; */
  width: 32px;
  height: 32px;
  text-align: center;
  background: var(--activeTextColor);
  color: white;
  /* border-radius: 100% 0% 100% 0% / 100% 100% 0% 0%; */
  position: fixed;
  bottom: 60px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.minimizer_body {
  width: 220px;
  background: var(--botoomMinimaizerBodyBg);
  /* padding-top: 21px; */
  /* padding-left: 10px; */
  right: 5px;
  /* padding-bottom: 40px; */
  /* border: 1px solid #5aba81; */
  z-index: 9999;
  bottom: 93px;

}
.hoverColor:hover{
  background: var(--selectingTimeFeetColorBg);
 cursor: pointer;
}
</style>
